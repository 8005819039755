<template>
    <BaseScene
        name="fool-fight"
        :background="ResourceManager.Get('scenes.foolfight')"
        @leave="OnLeaveScene" 
        @enter="OnEnterScene" 
    >
        <div class="curtain"></div>

        <template v-if="playerCharacter && aiCharacter">
            <div class="positioner" :style="aiPositionerStyles" :class="{hit: aiIsHit}">
                <div :class="'ai character ' + aiCharacter" :style="aiStyles" ref="elAiCharacter">
                    <div class="hitbox" :style="aiHitboxStyles" ref="aiHitbox">
                        <div class="range" ref="aiRange" :style="{width: characters[aiCharacter].range + 'px'}"></div>
                        <div class="healthbar">
                            <div class="current-healthbar" :style="{width: aiPercentHealth + '%'}"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="positioner" :style="playerPositionerStyles" :class="{hit: playerIsHit}">
                <div :class="'character ' + playerCharacter" :style="playerStyles" ref="elPlayerCharacter">
                    <div class="hitbox" :style="playerHitboxStyles" ref="playerHitbox">
                        <div class="range" ref="playerRange" :style="{width: characters[playerCharacter].range + 'px'}"></div>
                        <div class="healthbar" :style="{transform: `scale(${playerPosition.orientation})`}">
                            <div class="current-healthbar" :style="{width: playerPercentHealth + '%'}"></div>
                        </div>                
                    </div>                    
                </div>
            </div>
        </template>
        <template v-else>
            <BaseOverlay :showOnInit="true" :dynamicHeight="true" :noCloseButton="true">
                <h3>{{ this.cms.get("translations").get('character-selection') }}</h3>

                <div class="avatar-selection">
                    <div 
                        :class="{avatar, active: preselectedAvatars.player == key}" 
                        v-for="(avatar, key) in characters" 
                        :key="key" 
                        @click="preselectAvatar('player', key)"
                        :style="{'background-image': `url(${ResourceManager.Get(key + '.splash')})`}"
                    >
                        <!-- <div class="name">{{ key }}</div> -->
                    </div>
                </div>

                <div class="versus">vs.</div>

                <div class="avatar-selection">
                    <div 
                        :class="{avatar, active: preselectedAvatars.ai == key}" 
                        v-for="(avatar, key) in characters" 
                        :key="key" 
                        @click="preselectAvatar('ai', key)"
                        :style="{'background-image': `url(${ResourceManager.Get(key + '.splash')})`}"
                    >
                        <div class="disabled"
                            v-if="checkDisabledAvatar(key) == true"
                        > × </div>
                        <!-- <div class="name">{{ key }}</div> -->
                    </div>
                </div>             

                <div class="buttons">
                    <button class="help-button" @click="$refs.explanationOverlay.show()">
                        {{ this.cms.get("translations").get('help') }}
                    </button>
                    <button class="btn-fight" @click="startMatch">
                        {{ this.cms.get("translations").get('and-go') }}
                    </button>   
                </div>
            </BaseOverlay>
        </template>

        <BaseOverlay ref="endOfGameOverlay" noCloseButton="true" :dynamicHeight="true">
            <h3 v-if="playerHealth <= 0">{{ this.cms.get("translations").get('lost') }}!</h3>
            <h3 v-else>{{ this.cms.get("translations").get('won') }}!</h3>
            <button class="btn-fight" @click="playAgain">Nochmal spielen</button>
        </BaseOverlay>

        <BaseOverlay class="explanation-overlay" ref="explanationOverlay" :showOnInit="false" :width="900">
            <h3>{{ this.cms.get("translations").get('explanation-headline') }}</h3>
            <p class="description" style="text-align: center" v-html="cms.get('translations').get('foolfight-explanation')"></p>
        </BaseOverlay>

        <div class="touch-controls" v-if="playerCharacter && aiCharacter">
            <div class="l" @touchstart="input.left = true; aiCanDoStuff = true" @touchend="input.left = false">&larr;</div>
            <div class="a" @click="playAttack(); aiCanDoStuff = true">&#9876;</div>
            <div class="r" @touchstart="input.right = true; aiCanDoStuff = true;" @touchend="input.right = false">&rarr;</div>
        </div>
    </BaseScene>
</template>

<script>
import BaseScene from '../BaseScene.vue'
import { Howl } from 'howler';
import { ref } from 'vue';
import ResourceManager from "@/classes/resource-manager";
import BaseOverlay from "@/components/BaseOverlay";
import characters from "@/assets/foolfight/character-definitions";
import Cookies from 'js-cookie';

export default {
    inject: {
        cms: {
          default: null,
        },
    },       
    components: {
        BaseScene,
        BaseOverlay
        // SceneTrigger
    },
    setup() {
        const playerHitbox = ref(null);
        const elPlayerCharacter = ref(null);
        const elAiCharacter = ref(null);
        const aiRange = ref(null);
        const playerRange = ref(null);
        const endOfGameOverlay = ref(null);

        return {
            playerHitbox,
            elPlayerCharacter,
            elAiCharacter,
            aiRange,
            playerRange,
            endOfGameOverlay
        };
    },
    mounted() {
        document.addEventListener("keydown", key => {
            if (this.playerHealth <= 0) {
                return;
            }

            if (key.code == "ArrowLeft") {
                this.input.left = true;
            }
            if (key.code == "ArrowRight") {
                this.input.right = true;
            }          

            this.aiCanDoStuff = true;
        });

        document.addEventListener("keyup", key => {
            if (this.playerHealth <= 0) {
                return;
            }

            if (key.code == "ArrowLeft") {
                this.input.left = false;
            }

            if (key.code == "ArrowRight") {
                this.input.right = false;
            }

            if (key.code == "Space") {
                this.playAttack();
            }

            this.aiCanDoStuff = true;
        });

        let lastTimestamp = undefined;
        let nextPlayerSpriteIdx = 0, nextAiSpriteIdx = 0;
        
        this.currentPlayerAnimation = this.playerAnimation;
        this.currentAiAnimation = this.aiAnimation;

        const fpsInterval = 1000 / 60;
        let now = 0;
        let then = Date.now();

        const raf = (t) => {
            now = Date.now();
            let elapsed = now - then;

            if (lastTimestamp == undefined) {
                lastTimestamp = t;
            }

            if (!this.playerCharacter || !this.aiCharacter) {
                window.requestAnimationFrame(raf);
                return;
            }

            if (elapsed > fpsInterval) {
                const deltaTime = t - lastTimestamp;

                nextPlayerSpriteIdx = this.doPlayerAnimationFrameLogic(deltaTime, nextPlayerSpriteIdx);
                nextAiSpriteIdx = this.doAiAnimationFrameLogic(deltaTime, nextAiSpriteIdx);
                this.updateOrientation(deltaTime);
                
                lastTimestamp = t;

                then = now - (elapsed % fpsInterval);
            }
            
            window.requestAnimationFrame(raf);                  
        }

        window.requestAnimationFrame(raf);
    },
    data() {
        return {
            ResourceManager,
            bgm: null,
            input: {
                left: false,
                right: false,
                attacking: false,
            },
            characters,
            preselectedAvatars: {
                player: '',
                ai: ''
            },

            // player
            playerCharacter: '',
            playerSpritePointer: 0,
            playerPosition: {
                x: 0,
                y: 0,
                orientation: 1,
                rotation: 0,
                scale: 1                
            },
            playerHealth: 0,
            playerIsHit: false,
            currentPlayerAnimation: 'idle',

            // ai
            aiCharacter: '',
            aiSpritePointer: 0,
            aiPosition: {
                x: 0,
                y: 0,
                orientation: -1,
                rotation: 0,
                scale: 1
            },
            aiAnimation: 'idle',
            aiHealth: 0,
            aiIsHit: false,
            aiIsAttacking: false,
            aiWillAttack: false,
            aiCanAttack: true,
            aiCanDoStuff: false,
            currentAiAnimation: 'idle'
        };
    },
    methods: {
        playAgain() {
            this.playerCharacter = '';
            this.playerHealth = 0;
            this.playerPosition = {
                x: 0,
                y: 0,
                orientation: 1,
                rotation: 0,
                scale: 1                
            },
            this.currentPlayerAnimation = 'idle';

            this.aiCharacter = '';
            this.aiHealth = 0;
            this.aiPosition = {
                x: 0,
                y: 0,
                orientation: -1,
                rotation: 0,
                scale: 1
            };
            this.aiAnimation = this.currentAiAnimation = 'idle';
            this.aiCanDoStuff = false;

            this.preselectedAvatars = {
                player: '',
                ai: ''
            };

            this.$refs.endOfGameOverlay?.hide();
        },
        checkWinConditions() {
            if (this.playerHealth <= 0) {
                
                const sound = new Howl({
                    src: [ResourceManager.Get('shorts.boo')],
                    loop: false,
                    volume: 1
                });

                sound.play();
                this.$refs.endOfGameOverlay.show();
                return;
            }

            if (this.aiHealth <= 0) {
                
                let wonFights = {};

                // wird mehrmals aufgerufen!!

                if( Cookies.get('wonFights') ) {
                    wonFights = JSON.parse( Cookies.get('wonFights') );
                    if(wonFights[this.playerCharacter]) {
                        wonFights[this.playerCharacter].push(this.aiCharacter);
                    } else {
                        wonFights[this.playerCharacter] = [this.aiCharacter];
                    }
                } else {
                    wonFights = {
                        [this.playerCharacter]: [
                            this.aiCharacter,
                        ]
                    };
                }

                Cookies.set('wonFights', JSON.stringify(wonFights));
                
                const sound = new Howl({
                    src: [ResourceManager.Get('shorts.cheer')],
                    loop: false,
                    volume: 1
                });

                sound.play();
                this.$refs.endOfGameOverlay.show();
                return;
            }

        },
        playAttack() {
            if (this.input.attacking) {
                return;
            }

            this.input.attacking = true;

            const character = this.characters[this.playerCharacter];

            const sound = new Howl({
                src: [character.sounds.attack.src],
                loop: false,
                volume: 1
            });

            const willHit = this.playerWillHit();       
            const direction = this.playerPosition.orientation;            

            sound.play();

            setTimeout(() => {
                this.input.attacking = false;

                if (willHit) {
                    this.aiHealth -= character.damage;
                    this.checkWinConditions();
                    this.aiIsHit = true;
                    this.aiPosition.x += 50 * direction;

                    setTimeout(() => {
                        this.aiIsHit = false;
                    }, 150);
                }
            }, character.sprites.attack.speed);
        },
        playAiAttack() {
            if (this.aiIsAttacking) {
                return false;
            }

            this.aiIsAttacking = true;

            const character = this.characters[this.aiCharacter];

            const sound = new Howl({
                src: [character.sounds.attack.src],
                loop: false,
                volume: 1
            });

            const willHit = this.aiWillHit();       
            const direction = this.aiPosition.orientation;            

            sound.play();

            setTimeout(() => {
                this.aiIsAttacking = false;

                if (willHit) {
                    this.playerHealth -= character.damage;
                    this.checkWinConditions();
                    this.playerIsHit = true;
                    this.playerPosition.x += 50 * direction;

                    setTimeout(() => {
                        this.playerIsHit = false;
                    }, 150);
                }
            }, character.sprites.attack.speed);
        },
        updateOrientation(deltaTime) {
            if (this.input.attacking) {
                return;
            }

            if (this.input.left) {
                this.playerPosition.orientation = -1;
            }

            if (this.input.right) {
                this.playerPosition.orientation = 1;
            }
        },
        checkDisabledAvatar(avatar) {

            if(Cookies.get('wonFights')) {
                const wonFights = JSON.parse(Cookies.get('wonFights'));
                let setAiChar = true;

                if(wonFights[this.preselectedAvatars['player']]) {
                    wonFights[this.preselectedAvatars['player']].forEach(element => {
                        if(element == avatar) {
                            setAiChar = false;
                        }
                    });
                }

                if(setAiChar == false) {
                    return true;
                } else {
                    return false;
                }

            } else {
                return false;
            }

        },
        preselectAvatar(_for, who) {

            this.preselectedAvatars[_for] = who;

            // check if AI disabled when changing character -> if disbaled block ai selection
            if(this.preselectedAvatars['ai'] != '') {
                if( this.checkDisabledAvatar(this.preselectedAvatars['ai']) == true ){
                    this.preselectedAvatars['ai'] = '';
                } 
            }
            
        },
        startMatch() {
            this.playerCharacter = this.preselectedAvatars.player;
            this.playerHealth = this.characters[this.playerCharacter].health;

            this.aiCharacter = this.preselectedAvatars.ai;
            this.aiHealth = this.characters[this.aiCharacter].health;         
        },
        doPlayerAnimationFrameLogic(deltaTime, nextPlayerSpriteIdx) {
            const character = this.characters[this.playerCharacter];
            const sprite = character.sprites[this.currentPlayerAnimation];

            nextPlayerSpriteIdx += deltaTime;
            if (nextPlayerSpriteIdx >= (sprite.speed / sprite.count)) {
                this.playerSpritePointer++;

                if (this.currentPlayerAnimation == 'walk') {

                    const hitbox = this.$refs.playerHitbox.getBoundingClientRect();
                    const offset = 50;

                    if (this.playerPosition.orientation == 1) {
                        if (hitbox.x + hitbox.width <= document.body.clientWidth - offset) {
                            this.playerPosition.x += Math.floor(deltaTime * character.speed) * this.playerPosition.orientation;    
                        }
                    } else {
                        if (hitbox.x >= offset) {
                            this.playerPosition.x += Math.floor(deltaTime * character.speed) * this.playerPosition.orientation;    
                        }                        
                    }
                }

                if (this.playerSpritePointer >= sprite.count) {
                    this.playerSpritePointer = 0;

                    if (this.playerAnimation != this.currentPlayerAnimation) {
                        if (sprite.exit) {
                            this.currentPlayerAnimation = this.playerAnimation;
                        }
                    }                    
                }

                nextPlayerSpriteIdx = 0;
            }

            if (this.playerAnimation != this.currentPlayerAnimation) {
                if (!sprite.exit) {
                    this.currentPlayerAnimation = this.playerAnimation;
                    this.playerSpritePointer = 0;
                }
            }

            //death anim
            if (this.playerHealth <= 0) {
                this.playerPosition.x += 1 * deltaTime * this.aiPosition.orientation;
                this.playerPosition.y -= 1 * deltaTime;
                this.playerPosition.rotation += 1 * deltaTime;
                this.playerPosition.scale -= 0.001 * deltaTime;

                if (this.playerPosition.scale <= 0) {
                    this.playerPosition.scale = 0;
                }
            }            

            return nextPlayerSpriteIdx;
        },
        doAiAnimationFrameLogic(deltaTime, nextAiSpriteIdx) {
            const character = this.characters[this.aiCharacter];
            const sprite = character.sprites[this.currentAiAnimation];

            nextAiSpriteIdx += deltaTime;
            if (nextAiSpriteIdx >= (sprite.speed / sprite.count)) {
                this.aiSpritePointer++;

                if (this.currentAiAnimation == 'walk') {

                    const hitbox = this.$refs.aiHitbox.getBoundingClientRect();
                    const offset = 50;

                    if (this.aiPosition.orientation == 1) {
                        if (hitbox.x + hitbox.width <= document.body.clientWidth - offset) {
                            this.aiPosition.x += Math.floor(deltaTime * character.speed) * this.aiPosition.orientation;    
                        }
                    } else {
                        if (hitbox.x >= offset) {
                            this.aiPosition.x += Math.floor(deltaTime * character.speed) * this.aiPosition.orientation;    
                        }                        
                    }
                }

                if (this.aiSpritePointer >= sprite.count) {
                    this.aiSpritePointer = 0;

                    if (this.aiAnimation != this.currentAiAnimation) {
                        if (sprite.exit) {
                            this.currentAiAnimation = this.aiAnimation;
                        }
                    }                    
                }

                nextAiSpriteIdx = 0;
            }

            if (this.aiAnimation != this.currentAiAnimation) {
                if (!sprite.exit) {
                    this.currentAiAnimation = this.aiAnimation;
                    this.aiSpritePointer = 0;
                }
            }

            //death anim
            if (this.aiHealth <= 0) {
                this.aiPosition.x += 1 * deltaTime * this.playerPosition.orientation;
                this.aiPosition.y -= 1 * deltaTime;
                this.aiPosition.rotation += 1 * deltaTime;
                this.aiPosition.scale -= 0.001 * deltaTime;

                if (this.aiPosition.scale <= 0) {
                    this.aiPosition.scale = 0;
                }
            }

            if (this.aiCanAttack) {
                if (!this.aiWillAttack && this.aiWillHit()) {
                    this.aiWillAttack = true;
                    this.aiCanAttack = false;
    
                    setTimeout(() => {
                        this.playAiAttack();     
                        this.aiWillAttack = false;           
                    }, 200);

                    setTimeout(() => {
                        this.aiCanAttack = true;
                    }, 1000);
                }
            }

            //movement
            const rect1 = this.$refs.elAiCharacter?.getBoundingClientRect();
            const rect2 = this.$refs.elPlayerCharacter?.getBoundingClientRect();

            const pX = rect1.left + (rect1.width / 2);
            const qX = rect2.left + (rect2.width / 2);

            if (pX < qX) {
                this.aiPosition.orientation = 1;
            } else {
                this.aiPosition.orientation = -1;
            }

            if (this.playerHealth <= 0 || !this.aiCanDoStuff) {
                this.aiAnimation = 'idle';
            } else {
                if (this.aiIsAttacking) {
                    this.aiAnimation = 'attack';
                } else {
                    if (this.getCharacterDistance() > 100) {
                        this.aiAnimation = 'walk';
                    } else {
                        this.aiAnimation = 'idle';
                    }
                }
            }

            return nextAiSpriteIdx;
        },
        playerWillHit() {
            if (this.getCharacterDistance() <= 100) {
                return true;
            }

            const character = this.characters[this.playerCharacter];

            const rect1 = this.$refs.playerRange?.getBoundingClientRect();
            const rect2 = this.$refs.aiHitbox?.getBoundingClientRect();

            if (!rect1 || !rect2) {
                return false;
            }

            if (this.playerPosition.orientation == 1) {
                if (rect1.left + character.range >= rect2.left && rect1.left < rect2.left) {
                    return true;
                }
            } else {
                if (rect1.left <= rect2.left + rect2.width && rect1.left + rect1.width >= rect2.left) {
                    return true;
                }
            }

            return false;
        },
        aiWillHit() {
            if (this.getCharacterDistance() <= 100) {
                return true;
            }

            const character = this.characters[this.aiCharacter];

            const rect1 = this.$refs.aiRange?.getBoundingClientRect();
            const rect2 = this.$refs.playerHitbox?.getBoundingClientRect();

            if (!rect1 || !rect2) {
                return false;
            }

            if (this.aiPosition.orientation == 1) {
                if (rect1.left + character.range >= rect2.left && rect1.left < rect2.left) {
                    return true;
                }
            } else {
                if (rect1.left <= rect2.left + rect2.width && rect1.left + rect1.width >= rect2.left) {
                    return true;
                }
            }

            return false;
        },
        getCharacterDistance() {
            const rect1 = this.$refs.elAiCharacter?.getBoundingClientRect();
            const rect2 = this.$refs.elPlayerCharacter?.getBoundingClientRect();

            const pX = rect1.left + (rect1.width / 2);
            const qX = rect2.left + (rect2.width / 2);

            return Math.abs(pX - qX);
        },
        OnEnterScene() {
            if (!this.bgm) {
                this.bgm = new Howl({
                    src: [ResourceManager.Get('laughsongs.arek')],
                    loop: true,
                    volume: 1,
                    html5: true
                });
            }
    
            if (!this.bgm.playing()) {
                this.bgm.play();
            }

            this.playAgain();
        },
        OnLeaveScene() {
            this.bgm.stop();
        }        
    },
    computed: {

        isWalking() {
            return this.input.left || this.input.right;
        },

        // PLAYER ##################################################################################
        playerAnimation() {
            let ret = 'idle';

            if (this.isWalking) {
                ret = 'walk';
            }

            if (this.input.attacking) {
                ret = 'attack';
            }

            return ret;
        },
        playerSprite() {
            // return ResourceManager.Get(`${this.playerCharacter}.${this.currentPlayerAnimation}`);
            return ResourceManager.Get(`${this.playerCharacter}.sprite`);
        },

        playerStyles() {

            const bpX = -(this.playerSpritePointer * (this.$refs?.elPlayerCharacter?.clientWidth));
            const bpY = this.characters[this.playerCharacter].sprites[this.currentPlayerAnimation].backgroundY;

            return {
                'background-image': `url('${this.playerSprite}')`,
                'background-position': `${bpX}px ${bpY}%`,
                'transform': `translate(-50%, -50%) scaleX(${this.playerPosition.orientation}) rotate(${this.playerPosition.rotation}deg) scale(${this.playerPosition.scale})`
            };
        },
        playerHitboxStyles() {
            const character = this.characters[this.playerCharacter];

            return {
                'width': `${character.hitbox.w}vw`,
                'height': `${character.hitbox.h}vw`,
                'margin-left': `${character.hitbox.x}vw`,
                'margin-top': `${character.hitbox.y}vw`,
            };
        },
        playerPositionerStyles() {
            return {
                'left': `calc(50% + ${this.playerPosition.x}px)`,
                'top': `calc(50% + ${this.playerPosition.y}px)`,
            };
        },
        playerPercentHealth() {
            return this.playerHealth / this.characters[this.playerCharacter].health * 100;
        },

        // AI ######################################################################################
        aiSprite() {
            // return ResourceManager.Get(`${this.aiCharacter}.${this.currentAiAnimation}`);
            return ResourceManager.Get(`${this.aiCharacter}.sprite`);
        },
        aiStyles() {
            const bpX = -(this.aiSpritePointer * (this.$refs?.elAiCharacter?.clientWidth));
            const bpY = this.characters[this.aiCharacter].sprites[this.currentAiAnimation].backgroundY;

            return {
                'background-image': `url('${this.aiSprite}')`,
                'background-position': `${bpX}px ${bpY}%`,
                'transform': `translate(-50%, -50%) scaleX(${this.aiPosition.orientation}) rotate(${this.aiPosition.rotation}deg) scale(${this.aiPosition.scale})`
            };
        },
        aiHitboxStyles() {
            const character = this.characters[this.aiCharacter];

            return {
                'width': `${character.hitbox.w}vw`,
                'height': `${character.hitbox.h}vw`,
                'margin-left': `${character.hitbox.x}vw`,
                'margin-top': `${character.hitbox.y}vw`,
            };
        },        
        aiPositionerStyles() {            
            return {
                'left': `calc(50% + ${this.aiPosition.x}px)`,
                'top': `calc(50% + ${this.aiPosition.y}px)`,
            };
        },
        aiPercentHealth() {
            return this.aiHealth / this.characters[this.aiCharacter].health * 100;
        },        
    }
}
</script>

<style lang="scss">

.scene-fool-fight {

    .touch-controls {
        background: rgba(255, 255, 255, 0.5);
        position: fixed;
        bottom: 20%;
        left: 50%;
        margin-left: -81px;
        z-index: 82;
        display: none;

        .l,
        .r,
        .a {
            padding: 10px 10px;
            text-align: center;
            color: black;
            font-size: 30px;
            user-select: none;
            cursor: pointer;
            transition: .35s;

            &:hover {
                background: rgba(255, 255, 255, 0.7);
            }
        }

        .a {
            padding: 10px 20px;
        }
    }

    .curtain {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/foolfight/curtain.png');
        background-size: cover;
        background-position: center;        
        z-index: 81;
    }

    .character {
        background-size: auto 300%;
        // will-change: background-position;
        user-select: none;
        position: absolute;
        left: 50%;
        top: 50%;
        @include xy-ar(-15, 10);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;        
        backface-visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        &.ai {
            @include xy-ar(15, 10);
        }

        &.fool,
        &.devil {
            @include width-ar(52.084);
            @include height-ar(25);
        }

        &.crocodile {
            @include width-ar(54.167);
            @include height-ar(20.834);            
        }

        &.granny,
        &.policeman,
        &.princess {
            @include width-ar(41.668);
            @include height-ar(20.834);            
        }

        .hitbox {
            // border: 1px solid red;
            position: relative;

            .range {
                opacity: 0;
                background: greenyellow;
                position: absolute;
                top: 50%;
                left: 100%;
                height: 1px;
            }

            .healthbar {
                position: absolute;
                height: 20px;
                width: 10.4vw;
                background: black;
                left: 50%;
                margin-left: -5.2vw;
                top: -30px;
                -webkit-box-shadow: 5px 5px 15px -7px #000000; 
                box-shadow: 5px 5px 15px -7px #000000;                    

                .current-healthbar {
                    background: crimson;
                    position: absolute;
                    height: 20px;
                    border: 1px solid black;
                }
            }
        }
    }

    .positioner {
        position: absolute;
        // z-index: 100;

        &.hit {
            // filter: hue-rotate(90deg);
            filter: grayscale(100%);
            opacity: 0.7;

            .hitbox {
                // filter: hue-rotate(-90deg);
                filter: grayscale(0%);
                opacity: 1;
            }
        }
    }

    .avatar-selection {
        display: flex;
        flex-wrap: wrap;

        .avatar {
            margin: 10px;
            width: 64px;
            height: 64px;
            border: 1px solid white;
            border-radius: 3px;
            cursor: pointer;
            transition: .35s;
            overflow: hidden;

            &.active,
            &:hover {
                background: $hl-1;
            }

            .name {
                text-transform: uppercase;
                font-size: 10px;
                text-align: center;
            }

            .disabled  {
                width: 100%;
                font-size: 170px;
                text-align: center;
                margin-top: 8px;
                font-family: 'Amatic SC', cursive;
                color: crimson;

            }
        }
    }

    .versus {
        color: $hl-1;
        @extend .font-hl;
        text-align: center;
        font-size: 60px;
        font-weight: bold;
        line-height: 80px;
        text-transform: lowercase;
        margin-bottom: 5px;
    }

    button {
        background: $hl-1;
        outline: 0;
        border: 0;
        padding: 0px 20px;
        color: white;
        @extend .font-hl;
        font-size: 40px;
        cursor: pointer;
        margin: 0 20px;
        display: block;
        margin-top: 40px;        
        margin-bottom: 20px;        

        &.help-button {
            background: $hl-2;
        }        
    }

    .buttons {
        display: flex;
        justify-content: center;
    }    

    h3 {
        color: white;
        @extend .font-hl;
        font-size: 60px;
        text-align: center;
        margin: 40px 0;
        line-height: 60px;
    }   
}



// add for mobile 05.04.2022
@media (max-width: 800px) {
    .scene-fool-fight {
        .touch-controls {
            display: flex;
        }
        
        .versus {
            font-size: 40px;
            line-height: 60px;
            margin-bottom: 5px;
        }

        h3 {
            font-size: 40px;
            line-height: 40px;
            margin: 20px 0;
        }   
    }
}
</style>