import ResourceManager from '../../classes/resource-manager';

export default {
    fool: {
        width: 1000,
        height: 480,
        speed: 0.8,
        range: 300,
        health: 100,
        damage: 10,
        hitbox: {
            w: 6.5,
            h: 16,
            x: 0,
            y: 3
        },
        sprites: {
            idle: {
                src: ResourceManager.Get("fool.sprite"),
                count: 2,
                speed: 500,
                backgroundY: 0,
            },
            walk: {
                src: ResourceManager.Get("fool.sprite"),
                count: 10,
                speed: 600,
                backgroundY: 50,
            },                        
            attack: {
                src: ResourceManager.Get("fool.sprite"),
                count: 12,
                speed: 300,
                backgroundY: 100,
                exit: true
            },
        },
        sounds: {
            attack: {
                src: ResourceManager.Get("shorts.fool.attack")
            }
        }
    },
    devil: {
        width: 1000,
        height: 460,
        speed: 0.8,
        range: 200,
        health: 100,
        damage: 10,
        hitbox: {
            w: 6.5,
            h: 16,
            x: 0,
            y: 3
        },
        sprites: {
            idle: {
                src: ResourceManager.Get("devil.sprite"),
                count: 2,
                speed: 500,
                backgroundY: 0,
            },
            walk: {
                src: ResourceManager.Get("devil.sprite"),
                count: 7,
                speed: 420,
                backgroundY: 50,
            },                        
            attack: {
                src: ResourceManager.Get("devil.sprite"),
                count: 10,
                speed: 300,
                backgroundY: 100,
                exit: true
            },
        },
        sounds: {
            attack: {
                src: ResourceManager.Get("shorts.devil.attack")
            }
        }
    },
    crocodile: {
        width: 1000,
        height: 480,
        speed: 0.8,
        range: 140,
        health: 100,
        damage: 10,
        hitbox: {
            w: 17,
            h: 16,
            x: 0,
            y: 3
        },
        sprites: {
            idle: {
                src: ResourceManager.Get("crocodile.sprite"),
                count: 2,
                speed: 500,
                backgroundY: 0,
            },
            walk: {
                src: ResourceManager.Get("crocodile.sprite"),
                count: 3,
                speed: 180,
                backgroundY: 50,
            },                        
            attack: {
                src: ResourceManager.Get("crocodile.sprite"),
                count: 10,
                speed: 400,
                backgroundY: 100,
                exit: true
            },
        },
        sounds: {
            attack: {
                src: ResourceManager.Get("shorts.crocodile.attack")
            }
        }
    },
    granny: {
        width: 1000,
        height: 480,
        speed: 0.8,
        range: 180,
        health: 100,
        damage: 10,
        hitbox: {
            w: 6.5,
            h: 16,
            x: 0,
            y: 3
        },
        sprites: {
            idle: {
                src: ResourceManager.Get("granny.sprite"),
                count: 2,
                speed: 500,
                backgroundY: 0,
            },
            walk: {
                src: ResourceManager.Get("granny.sprite"),
                count: 6,
                speed: 360,
                backgroundY: 50,
            },                        
            attack: {
                src: ResourceManager.Get("granny.sprite"),
                count: 11,
                speed: 300,
                backgroundY: 100,
                exit: true
            },
        },
        sounds: {
            attack: {
                src: ResourceManager.Get("shorts.granny.attack")
            }
        }
    },
    policeman: {
        width: 1000,
        height: 480,
        speed: 0.8,
        range: 180,
        health: 100,
        damage: 10,
        hitbox: {
            w: 6.5,
            h: 18,
            x: 0,
            y: 0
        },
        sprites: {
            idle: {
                src: ResourceManager.Get("policeman.sprite"),
                count: 2,
                speed: 500,
                backgroundY: 0,
            },
            walk: {
                src: ResourceManager.Get("policeman.sprite"),
                count: 11,
                speed: 600,
                backgroundY: 50,
            },                        
            attack: {
                src: ResourceManager.Get("policeman.sprite"),
                count: 10,
                speed: 300,
                backgroundY: 100,
                exit: true
            },
        },
        sounds: {
            attack: {
                src: ResourceManager.Get("shorts.policeman.attack")
            }
        }
    },
    princess: {
        width: 1000,
        height: 480,
        speed: 0.8,
        range: 180,
        health: 100,
        damage: 10,
        hitbox: {
            w: 6.5,
            h: 16,
            x: 0,
            y: 3
        },
        sprites: {
            idle: {
                src: ResourceManager.Get("princess.sprite"),
                count: 6,
                speed: 500,
                backgroundY: 0,
            },
            walk: {
                src: ResourceManager.Get("princess.sprite"),
                count: 4,
                speed: 240,
                backgroundY: 50,
            },                        
            attack: {
                src: ResourceManager.Get("princess.sprite"),
                count: 11,
                speed: 300,
                backgroundY: 100,
                exit: true
            },
        },
        sounds: {
            attack: {
                src: ResourceManager.Get("shorts.princess.attack")
            }
        }
    },
}